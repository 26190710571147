import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FirebaseConfig } from '../configs'

@NgModule({
  imports: [CommonModule],
})
export class FirebaseModule {
  static forRoot(config: FirebaseConfig): ModuleWithProviders<FirebaseModule> {
    return {
      ngModule: FirebaseModule,
      providers: [
        {
          provide: FirebaseConfig,
          useValue: config,
        },
      ],
    }
  }
}
