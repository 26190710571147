import { APP_INITIALIZER } from '@angular/core'
import { FirebaseConfig } from '@logichat/firebase'
import { BootstrapConfig } from '../config'

export const firebaseConfig: FirebaseConfig = {
  apiKey: '',
  authDomain: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: '',
  appId: '',
  measurementId: '',
}

const factory = (firebase: FirebaseConfig, config: BootstrapConfig) => {
  return () => {
    firebase.apiKey = config.firebase.apiKey
    firebase.authDomain = config.firebase.authDomain
    firebase.projectId = config.firebase.projectId
    firebase.storageBucket = config.firebase.storageBucket
    firebase.messagingSenderId = config.firebase.messagingSenderId
    firebase.appId = config.firebase.appId
    firebase.measurementId = config.firebase.measurementId
  }
}

export const firebaseConfigProvider = {
  provide: APP_INITIALIZER,
  useFactory: factory,
  deps: [FirebaseConfig, BootstrapConfig],
  multi: true,
}
