import { appConfig } from './app/app.config'

import { bootstrapApplication } from '@angular/platform-browser'

import { AppComponent } from './app/app.component'
import { BootstrapConfig } from './app/shared/config'

fetch('assets/bootstrap-settings.config.json')
  .then((res: Response) => res.json())
  .then((bootstrap: BootstrapConfig) => {
    bootStrapAngular(bootstrap)
  })

function bootStrapAngular(config: BootstrapConfig) {
  bootstrapApplication(AppComponent, appConfig(config)).catch((err) => console.error(err))
}
