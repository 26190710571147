import { Routes } from '@angular/router'
import { DASHBOARD_ROOT_ROUTE, PORTAL_ROOT_ROUTE } from './shared/config'

const routes: Routes = [
  {
    path: '',
    redirectTo: DASHBOARD_ROOT_ROUTE,
    pathMatch: 'full',
  },
  {
    path: PORTAL_ROOT_ROUTE,
    loadChildren: () => import('./pages/pages.routes'),
  },
  {
    path: '**',
    redirectTo: DASHBOARD_ROOT_ROUTE,
  },
]

export default routes
