import { provideHttpClient, withFetch } from '@angular/common/http'
import { ApplicationConfig, importProvidersFrom } from '@angular/core'
import { initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { getAuth, provideAuth } from '@angular/fire/auth'
import { getFirestore, provideFirestore } from '@angular/fire/firestore'
import { getFunctions, provideFunctions } from '@angular/fire/functions'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideRouter } from '@angular/router'
import { FirebaseModule } from '@logichat/firebase'
import { OpenAIModule } from '@logichat/openai'
import { PaymentModule } from '@logichat/payment'
import { environment } from '../environments/environment'
import routes from './app.routes'
import { BootstrapConfig, openaiConfig, openaiConfigProvider } from './shared/config'
import { firebaseConfig, firebaseConfigProvider } from './shared/providers'

export const appConfig = (config: BootstrapConfig): ApplicationConfig => ({
  providers: [
    {
      provide: BootstrapConfig,
      useValue: config,
    },
    provideHttpClient(withFetch()),
    openaiConfigProvider,
    firebaseConfigProvider,
    provideRouter(routes),
    provideAnimations(),

    importProvidersFrom(
      OpenAIModule.forRoot(openaiConfig),
      PaymentModule.forRoot({
        stripeToken: environment.stripe.token,
      }),
      FirebaseModule.forRoot(firebaseConfig),
      provideFirebaseApp(() => initializeApp(firebaseConfig)),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore()),
      provideFunctions(() => getFunctions()),
      MatSnackBarModule,
    ),
  ],
})
